.alert-form-error {
  width: 400px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.alert-full {
  width: 100% !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.alert-container {
  display: flex;
  justify-content: center;
}
