// onboard header
@use '../base/colors';

.onboard-header__content {
  text-align: center;
}

.onboard-header__logo {
  height: 240px;
}

.onboard-header__title {
  margin: 0;
  padding: 0;
}

// ------------------------------

// site bar

.site-bar__app-bar {
  background-color: colors.$background-light !important;
  border-bottom: 1px solid colors.$off-white !important;
}

.site-bar__toolbar {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
}

.site-bar__action {
  margin-right: 1.4rem !important;
  color: colors.$text-dark !important;
}

.site-bar__brand {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: colors.$text-dark;
}

.site-bar__titles {
  display: flex;
  justify-content: left;
  text-align: left;
  margin: 0;
  padding: 0;
}

.site-bar__brand:visited {
  color: colors.$text-dark;
}

.site-bar__brand:hover {
  color: colors.$text-dark;
}

.site-bar__logo {
  height: 40px;
}

.site-bar__title {
  margin: 0;
  padding: 0;
  margin-left: 1rem;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: colors.$text-dark;
}

.site-bar__subtitle {
  margin: 0;
  padding: 0;
  margin-left: 1rem;
  font-family: 'IBM Plex Serif', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: colors.$text-muted;
}

/* Covers mobile versions of webkit */

@media only screen and (max-width: 991px) {
  .site-bar__toolbar {
    margin-left: 0.25rem;
  }

  .site-bar__title {
    margin-left: 0.25rem;
  }

  .site-bar__action {
    margin-right: 0.5rem !important;
  }

  .site-bar__titles {
    flex-direction: column;
    text-align: left;
    justify-content: space-around;
  }

  .site-bar__title {
    font-size: 1.4rem;
  }

  .site-bar__subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    margin-top: -0.5rem;
    margin-left: 0.25rem;
  }
}