@use '../base/colors';

@keyframes loading-animation {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.loading {
  animation: loading-animation 1s linear infinite alternate;
  width: 340px;
  height: 340px;
}

.loading__container {
  text-align: center;
}

.loading__message {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 500;
  color: colors.$text-lighter;
  text-align: center;
}
