$auditor-green: #4CB944;
$danger-red: #E84855;
$danger-red-10: #E848551A;
$danger-red-30: #E8485533;

$background-light: #F9F9F9;

$text-dark: #495057;
$text-muted: #6C757D;
$text-light: #F1F1F1;
$text-lighter: #00000036;
$text-extra-light: #FFFFFF36;

$caption-light: #FFFFFF89;

$white: #FFFFFF;
$off-white: #F1F1F1;
$white84: #FFFFFF54;
$black: #000000;
