@use 'colors';

body {
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
}

.spacer-16 {
  margin-top: 1rem;
}

.spacer-24 {
  margin-top: 1.5rem;
}

.bottom-spacer-16 {
  margin-bottom: 1rem;
}

.full-width {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.subtitle {
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
}

// icon styles

.icon-muted {
  color: colors.$text-muted;
}

.icon-light {
  color: colors.$text-lighter;
}

// ------------------------------

// general list styles

.empty-list {
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  color: colors.$text-muted;
}

.list-divider {
  border-color: colors.$off-white !important;
}

.list-divider__danger {
  border-color: colors.$danger-red-30 !important;
}

.list-card {
  border-color: colors.$off-white !important;
  border-width: 2px !important;
}

.list-card__danger {
  border-color: colors.$danger-red !important;
  border-width: 2px !important;
}

.list-item {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
}

.list-item-fullbleed {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  margin: 0 !important;
  padding: 0 !important;
}

.list-item-row {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.list-item-row__danger {
  display: flex;
  flex-flow: row;
  width: 100%;
  background-color: colors.$danger-red-10;
}

.list-item-action {
  display: flex;
  flex-flow: row wrap;
}

.list-item__title {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.2rem;
  align-self: flex-start;
}

.list-item__subtitle {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 1rem;
  color: colors.$text-muted;
  align-self: flex-start;
}

.list-item__content {
  flex-grow: 1;
}

.list-item__icon {
  margin: 0;
  padding: 0;
  flex-grow: 0;
  text-align: right;
  align-self: center;
}

.list-item-dark {
  background-color: colors.$background-light;
}

// ------------------------------

// dialog styles

.dialog-actions {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

// ------------------------------

// interaction styles

.click-target {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

// ------------------------------

// spacing styles

.margin-1 {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.indent-2 {
  padding-left: 2rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

// ------------------------------

/* override material ui styles that aren't controllable through the API */

.MuiOutlinedInput-notchedOutline {
  border-color: #F1F1F1 !important;
  border-width: 2px !important;
}

.MuiDialog-container {
  align-items: flex-start !important;
}

/* Covers mobile versions of webkit */

@media only screen and (max-width: 1200px) {
  body {
    min-height: -webkit-fill-available;
  }
}
