.textfield-400 {
  width: 400px;
}

/* Covers mobile versions of webkit */

@media only screen and (max-width: 991px) {
  .textfield-400 {
    width: 100%;
  }
}
