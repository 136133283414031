.button-primary {
  text-transform: capitalize !important;
  font-size: 1.2rem !important;
}

.button-min-240 {
  min-width: 240px !important;
}

.button-plain {
  text-transform: capitalize !important;
  font-size: 1rem !important;
}
