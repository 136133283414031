// home page styles
@use '../base/colors';

.home-page {
  display: flex;
  flex-direction: column;
}

.home-page__content {
  margin-top: 2.5rem;
  text-align: center;
}

// ------------------------------

// signup page styles

.signup-page {
  display: flex;
  flex-direction: column;
}

.signup-page__content {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  text-align: center;
}

.signup-page__form {
  justify-content: center;
}

// ------------------------------

// login page styles

.login-page {
  display: flex;
  flex-direction: column;
}

.login-page__content {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  text-align: center;
}

// ------------------------------

// contests page styles

.contests-page {
  display: flex;
  flex-direction: column;
}

.contests-page__content {
  text-align: center;
}

.contests-page__empty-contests {
  text-align: center;
}

.contests-page__empty-contests__img {
  height: 340px;
}

.contests-page__empty-contests__title {
  margin-top: 0;
}

.contests-page__section-title {
  font-family: 'IBM Plex Serif', serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: colors.$text-dark;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.contests-page__list-create {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: right;
}

// ------------------------------

// create contest page styles

.create-contest-page {
  display: flex;
  flex-direction: column;
}

.create-contest-page__content {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.create-contest-page__date-field {
  width: 100%;
}

.create-contest-page__form-button {
  text-align: center;
}

// ------------------------------

// contest page styles

.contest-page__content {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-left: 0rem;
  padding-right: 0rem;
}

.contest-page__column-info {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-left: 1rem;
  max-width: 30%;
}

.contest-page__column-wagers {
  flex-grow: 1;
}

.contest-page__settings {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.contest-page__upload-hint {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.contest-page__upload-form {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.contest-page__upload-col-1 {
  flex-grow: 1;
}

.contest-page__upload-col-2 {
  flex-grow: 0;
  margin-left: 1rem;
  align-self: center;
}

.contest-page__upload-btn-label {
  margin: 0;
  padding: 0;
  margin-left: 0.5rem;
}

.contest-page__upload-submit {
  margin: 1rem;
}

.contest-page__pending-upload {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.contest-page__player-name {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 1.1rem;
  color: colors.$text-dark;
  text-align: left;
}

.contest-page__player-subtitle {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  color: colors.$text-muted;
  text-align: left;
}

.contest-page__player-score {
  font-size: 1rem;
  font-weight: 400;
  color: colors.$text-muted;
}

.contest-page__wager-winnings {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
  color: colors.$text-muted;
  text-align: right;
}

.contest-page__wager-winnings__won {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  color: colors.$auditor-green;
  text-align: right;
}

// account page styles

.account-page__content {
  width: fit-content;
  margin: 0 auto;
}

.account-page__form-button {
  text-align: right;
}

.account-page__danger-title {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: colors.$text-dark;
  text-align: left;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.account-page__danger-message {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
  color: colors.$text-dark;
}

.account-page__danger-button {
  text-align: right;
  margin: 1rem;
}

// ------------------------------

/* Covers mobile versions of webkit */

@media only screen and (max-width: 991px) {
  // contests page styles

  .contests-page__top-button {
    width: 100%;
  }

  // ------------------------------

  // contest page styles

  .contest-page__content {
    flex-direction: column-reverse;
  }

  .contest-page__column-info {
    flex-grow: 0;
    flex-direction: column-reverse;
    margin-left: 0;
    max-width: 100%;
  }

  .contest-page__upload-col-1 {
    width: 65%;
  }
  
  .contest-page__upload-col-2 {
    width: 35%;
  }

  // ------------------------------
}
