@use 'sass:color';
@use 'colors';

.tagline {
  color: colors.$text-dark;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 700;
  font-size: 3.4rem;
}

.signup-page__subtitle {
  color: colors.$text-dark;
  font-size: 1.2rem;
}

.login-page__subtitle {
  color: colors.$text-dark;
  font-size: 1.2rem;
}

.primary-link {
  color: colors.$auditor-green;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: color.adjust(colors.$auditor-green, $lightness: -10%);
  }
}

.empty-content-title {
  font-family: 'IBM Plex Serif', serif;
  font-weight: 600;
  font-size: 2.4rem;
  color: colors.$text-light;
}

.form-hint {
  font-size: 0.8rem;
  font-weight: 500;
  color: colors.$text-muted;
}

.dialog-message {
  margin-top: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  color: colors.$text-dark;
}

.section-title {
  font-family: 'IBM Plex Serif', serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: colors.$text-dark;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.section-title__danger {
  color: colors.$danger-red;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 700;
  font-size: 1.6rem;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
